<!-- <head> 
    <script>
        gtag('event', 'conversion', {'send_to': 'AW-10937165436/Sn1FCPXA3ZQYEPzMn98o'});
    </script>
</head>  
 -->

<section *ngIf="submitted else other_content" class="thank-you pt-70 pb-70">
    <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
        <h2 class="vertical-align:center;">Thank You</h2>
        <p>We will reach out to you shortly</p>
        <a class="default-btn" ng-reflect-router-link="/" href="/" ><i class="flaticon-right"></i> Go Home<span></span></a>
    </div>
</section>

<ng-template #other_content>

    <section  class="page-title-area">

        <div class="container">
            <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
                <h2>{{pageTitle.title}}</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>{{pageTitle.title}}</li>
                </ul>
            </div>
        </div>
        <div class="shape-img1"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape1.svg" alt="image"></div>
        <div class="shape-img2"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape2.png" alt="image"></div>
        <div class="shape-img3"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape3.png" alt="image"></div>
    </section>

    <section class="contact-area pt-70 pb-50">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>
    </section>

    <div class="grid-container">
        <section class="contact-info-area contact-form left">
            <div class="container">
                <div class="row">
                    <div class="col-12 contact-content-container">
                        <div class="contact-info-box" *ngFor="let InfoBox1 of contactInfoBox1;">
                            <div class="back-icon">
                                <i class='{{InfoBox1.icon}}'></i>
                            </div>
                            <div class="content-info-box-content">
                                <div class="icon">
                                    <i class='{{InfoBox1.icon}}'></i>
                                </div>
                                <h3>{{InfoBox1.title}}</h3>
                                <p>{{InfoBox1.location}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 contact-content-container">
                        <div class="contact-info-box" *ngFor="let InfoBox2 of contactInfoBox2;">
                            <div class="back-icon">
                                <i class='{{InfoBox2.icon}}'></i>
                            </div>
                            <div class="content-info-box-content">
                                <div class="icon">
                                    <i class='{{InfoBox2.icon}}'></i>
                                </div>
                                <h3>{{InfoBox2.title}}</h3>
                                <p>Phone: <a href="tel:{{InfoBox2.number}}">{{InfoBox2.number}}</a></p>
                                <p>E-mail: <a href="mailto:{{InfoBox2.email}}">{{InfoBox2.email}}</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 contact-content-container">
                        <div class="contact-info-box" *ngFor="let InfoBox3 of contactInfoBox3;">
                            <div class="back-icon">
                                <i class='{{InfoBox3.icon}}'></i>
                            </div>
                            <div class="content-info-box-content">
                                <div class="icon">
                                    <i class='{{InfoBox3.icon}}'></i>
                                </div>
                                <h3>{{InfoBox3.title}}</h3>
                                <p>{{InfoBox3.text1}}</p>
                                <p>{{InfoBox3.text2}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="contact-info-area contact-form right pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group mb-3">
                                            <input type="text" [(ngModel)]="name" name="name" class="form-control" id="name" placeholder="Your name">
                                            <small *ngIf="errors.name" class="p-error">{{errors.name.message}}</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-3">
                                            <input type="email" [(ngModel)]="email" name="email" class="form-control" id="email" placeholder="Your email address">
                                            <small *ngIf="errors.email" class="p-error">{{errors.email.message}}</small>
                                        </div>
                                            
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-3">
                                            <input type="text" [(ngModel)]="phoneNumber" name="phone_number" class="form-control" id="phone_number" placeholder="Your phone number">
                                            <small *ngIf="errors.phone" class="p-error">{{errors.phone.message}}</small>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                        <ng-multiselect-dropdown
                                            [placeholder]="'Select product(s) you are interested in'"
                                            [settings]="dropdownSettings"
                                            [data]="dropdownList"
                                            (onSelect)="onItemSelect($event)"
                                            (onSelectAll)="onSelectAll($event)"
                                            (onDeSelect)="OnItemDeSelect($event)"
                                        >
                                        </ng-multiselect-dropdown>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="form-group mb-3">
                                            <textarea [(ngModel)]="message" name="message" id="message" class="form-control" cols="30" rows="3" placeholder="How do you intend to use Connect?"></textarea>
                                        </div>
                                        <small *ngIf="errors.phone" class="p-error">{{errors.phone.message}}</small>
                                    </div>
                                    <div class="col-12">
                                        <button (click)="submit()" class="default-btn submit-button" [disabled]="loading"><i class="{{loading ? '': 'flaticon-tick'}}"></i>{{loading ? "Sending" : "Start Now"}}<span></span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</ng-template>