import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { SolutionsComponent } from './components/common/solutions/solutions.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { HomepageFourComponent } from './components/pages/home/homepage-four.component';
import { HomefourBlogComponent } from './components/pages/home/homefour-blog/homefour-blog.component';
import { HomefourTestimonialsComponent } from './components/pages/home/homefour-testimonials/homefour-testimonials.component';
import { HomefourIndustriesServeComponent } from './components/pages/home/homefour-industries-serve/homefour-industries-serve.component';
import { HomefourServicesComponent } from './components/pages/home/homefour-services/homefour-services.component';
import { HomefourFunfactsComponent } from './components/pages/home/homefour-funfacts/homefour-funfacts.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomefourWhyChooseUsComponent } from './components/pages/home/homefour-why-choose-us/homefour-why-choose-us.component';
import { HomefourFeaturedServicesComponent } from './components/pages/home/homefour-featured-services/homefour-featured-services.component';
import { HomefourBannerComponent } from './components/pages/home/homefour-banner/homefour-banner.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { AboutpageAboutComponent } from './components/pages/about-page/aboutpage-about/aboutpage-about.component';
import { AboutpageHistoryComponent } from './components/pages/about-page/aboutpage-history/aboutpage-history.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from 'ng-lazyload-image';
import {ConnectComponent} from './components/pages/connect/connect.component';
import {ErrorPageComponent} from './components/pages/error-page/error-page.component';
import {ProductsPageComponent} from './components/pages/products-page/products-page.component';
import {ProductListingsComponent} from './components/pages/products-page/product-listings/product-listings.component';
import {LabListingsComponent} from './components/pages/products-page/lab-listings/lab-listings.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
// import {GoogleAnalyticsService} from './services/google-analytics.service';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    SolutionsComponent,
    SubscribeComponent,
    HomepageFourComponent,
    HomefourBlogComponent,
    HomefourTestimonialsComponent,
    HomefourIndustriesServeComponent,
    HomefourServicesComponent,
    HomefourFunfactsComponent,
    NavbarComponent,
    HomefourWhyChooseUsComponent,
    HomefourFeaturedServicesComponent,
    HomefourBannerComponent,
    AboutPageComponent,
    AboutpageAboutComponent,
    AboutpageHistoryComponent,
    ContactPageComponent,
    ConnectComponent,
      ErrorPageComponent,
      ProductsPageComponent,
      ProductListingsComponent,
      LabListingsComponent
  ],
    imports: [
        BrowserModule,
        LazyLoadImageModule,
        AppRoutingModule,
        HttpClientModule,
        NgMultiSelectDropDownModule.forRoot(),
        FormsModule,
    ],
  providers: [
      // GoogleAnalyticsService,
      { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
      provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
