<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
                <li>{{pageTitle.subTitle}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape3.png" alt="image"></div>
</section>

<section class="services-details-area" style="margin-top: 10px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div id="service-details" class="services-details-image" *ngFor="let DetailsImage of servicesDetailsImage;">
                            <img [defaultImage]="loadingImg" [lazyLoad]="DetailsImage.img" alt="image">
                        </div>
                    </div>
                </div>

                <div id="product-details" class="services-details-desc" *ngFor="let DetailsDesc of servicesDetailsDesc;">
                    <span class="sub-title">{{DetailsDesc.subTitle}}</span>
                    <h3>{{DetailsDesc.title1}}</h3>
                    <p class="long-text">{{DetailsDesc.paragraphText1}}</p>

                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="image">
                                <img [defaultImage]="loadingImg" [lazyLoad]="DetailsDesc.img1" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <h3>{{DetailsDesc.title2}}</h3>

                                <ul>
                                    <li class="feature" *ngFor="let ImportantFacts of servicesDetailsDescImportantFacts;">{{ImportantFacts.title}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- <p>{{DetailsDesc.paragraphText2}}</p> -->

                    <h3>{{DetailsDesc.title3}}</h3>

                    <section id="application-areas" class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let Application of servicesDetailsDescApplication;">
                            <div class="single-industries-serve-box">
                                <div class="icon">
                                    <i class="{{Application.icon}}"></i>
                                </div>

                                {{Application.title}}
                            </div>
                        </div>
                    </section>


               
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-details-info">
                    <ul class="services-list">
                        <li *ngFor="let ServicesList of sidebarServicesList;"><a (click)="scroll(ServicesList.link)">{{ServicesList.title}}</a></li>
                    </ul>

                    <div class="download-file">
                        <h3>Resources</h3>

                        <ul>
                            <li *ngFor="let DownloadFile of sidebarDownloadFile;"><a href="{{DownloadFile.link}}" target="_blank">{{DownloadFile.title}} <i class='{{DownloadFile.icon}}'></i></a></li>
                        </ul>
                    </div>

                    <div class="services-contact-info">
                        <h3>Contact Info</h3>

                        <ul>
                            <li *ngFor="let ContactInfo of sidebarContactInfo;">
                                <div class="icon">
                                    <i class='{{ContactInfo.icon}}'></i>
                                </div>
                                <span>{{ContactInfo.title}}:</span>
                                {{ContactInfo.subTitle}}
                            </li>
                        </ul>
                    </div>

                    <a style="width: 100%;" routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Begin Data Furnishing Today<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-related-services></app-related-services> -->
