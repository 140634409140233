<div class="navbar-area">
    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/logo.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/logo.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/logo.png'"
                         alt="Ubiquity logo" style="width: 180px;">
                    </picture>

                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Home</a></li>

                        <li class="nav-item"><a routerLink="/products" class="nav-link">Products <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/products/connect" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Connect</a></li>
                                <li class="nav-item"><a routerLink="/products/know-you" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Know You <img style="width: 12px" src="{{environment.imageStorageBucketUrl}}/lab.png" alt="Lab Image"></a></li>
                                <li class="nav-item"><a routerLink="/products/risk-radar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Risk Radar <img style="width: 12px" src="{{environment.imageStorageBucketUrl}}/lab.png" alt="Lab Image"></a></li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item"><a href="{{environment.blogUrl}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li> -->
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <!-- <a [href]="environment.startUrl + '/register'" class="default-btn"><i class="flaticon-right"></i>Start Free<span></span></a> -->
                            <a routerLink="/contact"  class="default-btn"><i class="flaticon-right"></i>Start Furnishing Free!<span></span></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
